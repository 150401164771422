<template>
	<div 
		v-if="username && messagesFiltered && messagesFiltered.length"
		class="fixed inset-0 overflow-hidden"
		style="z-index: 2000">
		
		<div class="absolute inset-0 bg-black opacity-25 ">
			<!--  -->
		</div>
		
		<div class="absolute inset-0 flex items-center justify-center m-12">
			
			<div
				v-for="message in [ messagesFiltered[0] ]"
				:key="'messageId_'+message.messageId"
				class="rounded-lg bg-white p-6 max-w-sm space-y-6 shadow-2xl">
				
				<!-- <div v-if="messagesFiltered.length">Du har {{ messagesFiltered.length }} uleste meldinger</div> -->
				
				<div>
					Fra <span class="text-black" v-if="message.author">{{ message.author?.given_name && message.author?.family_name ? message.author?.given_name +' '+ message.author?.family_name : 'Aministrator' }}</span>
					<div class="opacity-50 flex-grow space-x-4">
						<span>{{ message.createdAt | moment('lll') }}</span>
					</div>
				</div>
				
				<div 
					class="whitespace-pre-wrap"
					v-html="message.text" 
				/>
				
				<button 
					@click="onMarkMessageAsRead(message)"
					class="button submit w-full">
					
					Markér som lest
				</button>
			</div>
			
			<!-- 
			<div
				v-for="message in messagesFiltered"
				:key="'messageId_'+message.messageId"
				class="rounded-lg p-4"
				:class="[
					message.type == 'everyone' ? 'bg-blue-400' : '',
					message.type == 'departmentIds' ? 'bg-red-400' : '',
					message.type == 'usernames' ? 'bg-yellow-400' : '',
				]">
				
				<div class="flex mb-2 ">
					<div class="opacity-50 flex-grow space-x-4">
						<span>{{ message.createdAt | moment('lll') }}</span>
					</div>
					<div @click="onMarkMessageAsRead(message)">
						Skjul
					</div>
				</div>
				
				<div v-html="message.text" />
				
				<pre>{{ message }}</pre>
				<div v-if="message.type == 'everyone'">
					everyone
				</div>
				<div v-if="message.type == 'departmentIds'">
					departmentIds:
					<pre>{{ message.departmentIds }}</pre>
				</div>
				<div v-if="message.type == 'usernames'">
					usernames
					<pre>{{ message.usernames }}</pre>
				</div>
			</div> -->
		</div>
		
		<!--
		
		<pre>username: {{ username }}</pre>
		
		<hr>
		
		markedAsRead:
		<pre>{{ markedAsRead }}</pre>
		<br>
		
		<hr>
		
		messages:
		<pre>{{ messages }}</pre>
		<br>
		
		<hr>
		
		user:
		<pre v-if="user">{{ user }}</pre>
		<br>
		 -->
	</div>
</template>

<script>
export default {
    props: {
		context: {
			type: String,
			required: true,
		},
		inPortal: {
			type: Boolean,
			required: false,
		},
    },
	
	computed:{
		messages(){
			if (this.$store.getters.getMessages) {
				return this.$store.getters.getMessages;
			}
			else {
				return this.portalMessages;
			}
		},
		
		messagesFiltered(){
			if ( !this.messages || !this.messages.length ){
				return []
			}
			
			return this.messages
				.filter( message => {
					return !localStorage['message_'+message.messageId+':'+this.username];
				})
				.filter( message => {
					return !this.markedAsRead.includes( 'message_'+message.messageId+':'+this.username );
				})
				.filter( message => {
					switch (message.type) {
						case 'everyone':
							return true;
							break;
						case 'departmentIds':
							// app users
								if (
									this.signedInUserRole && 
									this.signedInUserRole == 'admin' ){
									
									return true;
								}
								else if (
									this.signedInUserRole && 
									Array.isArray( this.signedInUserRole ) && 
									Array.isArray( message.departmentIds ) ){
									
									return this.signedInUserRole.some( departmentId => message.departmentIds.includes( departmentId ) );
								}
								
							// portal users
								if (
									this.user && 
									this.user.departments == 'admin' ){
									
									return true;
								}
								else if (
									this.user && 
									this.user.departments &&
									Array.isArray( message.departmentIds ) ){
									
									return this.user.departments.some( departmentId => message.departmentIds.includes( departmentId ) );
								}
							break;
						case 'usernames':
							if (
								this.signedInUserRole && 
								this.signedInUserRole == 'admin' ){
								
								return true;
							}
							else if ( 
								this.username && 
								Array.isArray( message.usernames ) ){
								
								return message.usernames.includes( this.username );
							}
							
							break;
					}
				})
				
		},
		
		users(){
			return this.$store.getters.getUsers;
		},
		
		user(){
			return this.users.find( u => u.username == this.username);
		},
	},
	
	data(){
		return {
			loading: null,
			username: null,
			portalMessages: null,
			markedAsRead: [],
		}
	},
	
	methods: {
		onMarkMessageAsRead(message){
			localStorage['message_'+message.messageId+':'+this.username] = new Date().getTime();
			// this.departmentMessages = this.departmentMessages.filter( m => m.messageId != message.messageId);
			this.markedAsRead.push( 'message_'+message.messageId+':'+this.username );
		},
		
		async listMessages(){
			if (this.signedInUser) {
				this.username = this.signedInUser.username;
				
				this.loading = 'fetching';
				await this.$store.dispatch('listMessages');
				this.loading = null;
			}
			else {
				if ( this.account && this.account.accessToken) {
					try {
						const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/messages', {
							headers: {
								authorization: this.account.accessToken,
							},
						});
						
						this.portalMessages = response.data;
					} 
					catch (e) {
						this.error = { 
							type: 'error', 
							text: e.message || 'Kunne ikke hente meldinger'
						};
				
						console.error(e);
					} 
					finally {
						this.loading = null;
					}
				}
			}
		},
	},
	
	async mounted(){
		if (this.$route.params.username) {
			this.username = this.$route.params.username;
		}
		
		await this.listMessages();
	},
}
</script>
